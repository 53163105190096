import './App.css';

function App() {
  // redirect to a new URL
  window.location.replace("https://www.meta.com/en-gb/experiences/7352848058091664/");
  return (
    <div className="App">
      <header className="App-header">

      </header>
    </div>
  );
}

export default App;
